import {navigate} from 'gatsby';

export default (state) => {
  navigate('/app/offers/createOfferType', {
    state,
    replace: true,
  });
};

// export const toCheckoutLiquidCreate = (state) => {
//   navigate('/app/offers/create', {
//     state,
//     replace: true,
//   });
// };
//
// export const toCheckoutExtensionCreate = (state) => {
//   navigate('/app/offers/createCheckout', {
//     state,
//     replace: true,
//   });
// };
